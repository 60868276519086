import React from "react";
import {
  Box,
  Container,
  SimpleGrid,
  VStack,
  Text,
  Image,
  Flex,
  Button,
  Link,
  keyframes,
  Heading,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";

// Animation keyframes
const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const CertificationHeader = () => {
  return (
    <Box position="relative" py={16} overflow="hidden">
      {/* Background geometric shapes */}
      <Box position="absolute" inset={0} opacity={0.1}>
        <Box
          position="absolute"
          top={0}
          left="25%"
          w="128px"
          h="128px"
          bgGradient="linear(to-r, blue.500, purple.500)"
          borderRadius="full"
          filter="blur(40px)"
        />
        <Box
          position="absolute"
          top="25%"
          right="25%"
          w="128px"
          h="128px"
          bgGradient="linear(to-r, purple.500, blue.500)"
          borderRadius="full"
          filter="blur(40px)"
        />
      </Box>

      {/* Main content */}
      <VStack position="relative" zIndex={1} spacing={6}>
        <Heading
          as="h1"
          fontSize={{ base: "4xl", md: "6xl" }}
          fontWeight="bold"
          bgGradient="linear(to-r, blue.600, purple.600)"
          bgClip="text"
          textAlign="center"
        >
          {/* Certifications & Awards */}
          Certifications & Awards
        </Heading>

        {/* Animated underline */}
        <Box
          w="24"
          h="1"
          bgGradient="linear(to-r, blue.500, purple.500)"
          position="relative"
        >
          <Box
            position="absolute"
            top={0}
            left={0}
            w="full"
            h="full"
            bgGradient="linear(to-r, purple.500, blue.500)"
            animation={`${gradientAnimation} 3s ease infinite`}
          />
        </Box>

        {/* Subtitle */}
        {/* <Text
          color="gray.600"
          fontSize={{ base: "lg", md: "xl" }}
          maxW="2xl"
          textAlign="center"
          px={4}
        >
          Showcasing Our Professional Excellence and Industry Recognition
        </Text> */}
      </VStack>

      {/* Decorative elements */}
      <Flex
        position="absolute"
        bottom={0}
        left={0}
        w="full"
        justify="space-between"
        opacity={0.2}
      >
        <Box
          w="16"
          h="16"
          borderLeft="2px"
          borderTop="2px"
          borderColor="blue.500"
        />
        <Box
          w="16"
          h="16"
          borderRight="2px"
          borderTop="2px"
          borderColor="purple.500"
        />
      </Flex>
    </Box>
  );
};

const CertificationBox = ({ logo, title, description, year, pdfLink }) => (
  <Link href={pdfLink} isExternal _hover={{ textDecoration: "none" }}>
    <Box
      bg="white"
      p={6}
      borderRadius="lg"
      boxShadow="lg"
      transition="all 0.3s"
      _hover={{
        transform: "translateY(-5px)",
        boxShadow: "xl",
      }}
    >
      <VStack spacing={4} align="center">
        <Flex
          w="130px"
          h="70px"
          bg="blue.50"
          borderRadius="full"
          align="center"
          justify="center"
          mb={2}
          overflow="hidden"
        >
          <Image
            src={logo}
            alt={`${title} logo`}
            w="100%"
            h="100%"
            objectFit="cover"
            borderRadius="full"
          />
        </Flex>
        <Text
          fontSize="lg"
          fontWeight="bold"
          color="#000f40"
          textAlign="center"
        >
          {title}
        </Text>
        <Text fontSize="sm" color="gray.600" textAlign="center">
          {description}
        </Text>

        {year && (
          <Text fontSize="sm" color="blue.500" fontWeight="medium">
            {year}
          </Text>
        )}
        <Button
          size="sm"
          color="blue.500"
          rightIcon={<ExternalLinkIcon />}
        ></Button>
      </VStack>
    </Box>
  </Link>
);

const CertificationsSection = () => {
  const certifications = [
    {
      logo: "https://travel-time.ro/images/logoStats/TAROM-Logo.jpg",
      title: "Tarom",
      description: "",
      year: "2024",
      pdfLink:
        "https://travel-time.ro/images/documente/premii/Tarom%20Recomdantion%20Travel%20Time.pdf", // Replace with actual PDF link
    },
    {
      logo: "https://travel-time.ro/images/logoStats/Lufthansa-logo.jpg",
      title: "Lufthansa",
      description: "",
      year: "2025",
      pdfLink: "https://travel-time.ro/images/documente/premii/Lufthansa.pdf", // Replace with actual PDF link
    },
    {
      logo: "https://travel-time.ro/images/logoStats/Turkish-Airlines-logo.jpg",
      title: "Turkish Airlines",
      description: "",
      year: "2023",
      pdfLink: "https://travel-time.ro/images/documente/premii/TK%202023.jpg", // Replace with actual PDF link
    },
    {
      logo: "https://travel-time.ro/images/logoStats/Turkish-Airlines-logo.jpg",
      title: "Turkish Airlines",
      description: "",
      year: "2023",
      pdfLink:
        "https://travel-time.ro/images/documente/premii/Turkish%20Airlines%20Recomdantion%20Travel%20Time.pdf", // Replace with actual PDF link
    },
    {
      logo: "https://travel-time.ro/images/logoStats/Hahn-Air-Logo.jpg",
      title: "Hahn Air",
      description: "",
      year: "2017",
      pdfLink:
        "https://travel-time.ro/images/documente/premii/Hahn%20Air%202017%20thrid%20place.pdf", // Replace with actual PDF link
    },
    {
      logo: "https://travel-time.ro/images/logoStats/Qatar-Airways-logo.jpg",
      title: "Qatar Airways",
      description: "",
      year: "2018",
      pdfLink: "https://travel-time.ro/images/documente/premii/Qatar.pdf", // Replace with actual PDF link
    },
    {
      logo: "https://travel-time.ro/images/logoStats/LOT-Polish-Airlines-Logo.jpg",
      title: "Lot Polish",
      description: "",
      year: "2019",
      pdfLink: "https://travel-time.ro/images/documente/premii/LOT-2019.jpg", // Replace with actual PDF link
    },
    {
      logo: "https://travel-time.ro/images/logoStats/APG AIRLINES.jpg",
      title: "Apg Airlines",
      description: "",
      year: "2024",
      pdfLink: "https://travel-time.ro/images/documente/premii/AGP-2024.jpg", // Replace with actual PDF link
    },
    {
      logo: "https://travel-time.ro/images/logoStats/ISO-9001-2015.jpg",
      title: "ISO",
      description: "",
      year: "2024",
      pdfLink:
        "https://www.travel-time.ro/images/documente/certificat%20ISO%209001.pdf", // Replace with actual PDF link
    },
  ];

  return (
    <Container maxW="container.lg" py={12}>
      <CertificationHeader />
      <Box width={["100%", "100%", "75%"]} mx="auto">
        <VStack spacing={12}>
          <SimpleGrid columns={[1, 2, 2, 4]} spacing={8} w="full">
            {certifications.map((cert, index) => (
              <CertificationBox
                key={index}
                logo={cert.logo}
                title={cert.title}
                description={cert.description}
                year={cert.year}
                pdfLink={cert.pdfLink}
              />
            ))}
          </SimpleGrid>
        </VStack>
      </Box>
    </Container>
  );
};

export default CertificationsSection;
