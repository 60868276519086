// import React from "react";
// import {
//   MDBCard,
//   MDBCardBody,
//   MDBCardTitle,
//   MDBCardText,
//   MDBCardGroup,
// } from "mdb-react-ui-kit";

// const ListGroupExample = () => {
//   return (
//     <MDBCardGroup>
//       <MDBCard>
//         <MDBCardBody>
//           <MDBCardTitle className="fw-bold">Adresa Locatie</MDBCardTitle>
//           <MDBCardText className="fw-bold">
//             TRAVEL TIME
//             <br></br>
//             Punct de lucru: Strada Mircea Vulcanescu nr 45,Sector 1 Bucuresti
//           </MDBCardText>
//         </MDBCardBody>
//       </MDBCard>
//       <MDBCard>
//         <MDBCardBody>
//           <MDBCardTitle className="fw-bold">Detalii Firma</MDBCardTitle>
//           <MDBCardText className="fw-bold">
//             <p>SC TRAVEL TIME D&R SRL </p>
//             <p>
//               Sediu social: Strada ANTON PANN, Nr. 2, parter, ap.3, sector 3,
//               Bucuresti, Sector 3
//             </p>
//             <p>CIF: RO17926970</p>
//             <p> Nr. Reg. Com.: J40/15226/2005</p>
//           </MDBCardText>
//         </MDBCardBody>
//       </MDBCard>
//       <MDBCard>
//         <MDBCardBody>
//           <MDBCardTitle className="fw-bold">Informatii Contact</MDBCardTitle>
//           <MDBCardText className="fw-bold">
//             <p>Program de lucru: 09:00 - 18:00</p>
//             <span>
//               <strong>
//                 <i class="fas fa-envelope"></i> Adresa Generala:
//               </strong>
//               <a href="mailto:office@travel-time.ro"> office@travel-time.ro</a>
//             </span>
//             <br></br>
//             <span>
//               <strong>
//                 <i class="fas fa-envelope"></i> Departament Turism:
//               </strong>
//               <a href="mailto:turism@travel-time.ro"> turism@travel-time.ro</a>
//             </span>
//             <br></br>
//             <span>
//               <strong>
//                 <i class="fas fa-envelope"></i> Departament Ticketing:
//               </strong>
//               <a href="mailto:ticketing@travel-time.ro">
//                 {" "}
//                 ticketing@travel-time.ro
//               </a>
//             </span>
//             <br></br>
//             <span>
//               <strong>
//                 <i class="fas fa-phone"></i> Telefon:
//               </strong>
//               <a href="tel:0371121272">0371 12 12 72</a>
//             </span>
//           </MDBCardText>
//         </MDBCardBody>
//       </MDBCard>
//     </MDBCardGroup>
//   );
// };

// export default ListGroupExample;

import React from "react";
import {
  SimpleGrid,
  Box,
  VStack,
  Text,
  Heading,
  Link,
  Icon,
  useColorModeValue,
  HStack,
  Container,
} from "@chakra-ui/react";
import {
  FaMapMarkerAlt,
  FaBuilding,
  FaPhone,
  FaEnvelope,
  FaClock,
  FaIdCard,
} from "react-icons/fa";

const ContactCard = ({ title, icon, children }) => {
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Box
      p={6}
      borderRadius="xl"
      borderWidth="1px"
      borderColor={borderColor}
      bg={bgColor}
      boxShadow="lg"
      transition="all 0.3s"
      _hover={{
        transform: "translateY(-2px)",
        boxShadow: "xl",
      }}
    >
      <VStack spacing={4} align="flex-start">
        <HStack spacing={3}>
          <Icon as={icon} w={6} h={6} color="blue.400" />
          <Heading size="md" fontWeight="bold">
            {title}
          </Heading>
        </HStack>
        {children}
      </VStack>
    </Box>
  );
};

const ContactInfo = () => {
  const linkColor = useColorModeValue("blue.500", "blue.200");

  return (
    <Container maxW="7xl" py={8}>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8}>
        {/* Location Card */}
        <ContactCard title="Location Address" icon={FaMapMarkerAlt}>
          <VStack align="flex-start" spacing={2}>
            <Text fontWeight="bold" fontSize="lg">
              TRAVEL TIME
            </Text>
            <Text>
              Work Location: Mircea Vulcanescu Street, No. 45, Sector 1,
              Bucharest
            </Text>
          </VStack>
        </ContactCard>

        {/* Company Details Card */}
        <ContactCard title="Company Details" icon={FaBuilding}>
          <VStack align="flex-start" spacing={2}>
            <Text fontWeight="bold">SC TRAVEL TIME D&R SRL</Text>
            <Text>
              Registered Office: Anton Pann Street, No. 2, ground floor, ap.3,
              Sector 3
            </Text>
            <HStack>
              <Icon as={FaIdCard} color="gray.500" />
              <Text>CIF: RO17926970</Text>
            </HStack>
            <Text>No. Reg. Com.: J40/15226/2005</Text>
          </VStack>
        </ContactCard>

        {/* Contact Information Card */}
        <ContactCard title="Contact Information" icon={FaEnvelope}>
          <VStack align="flex-start" spacing={3} width="100%">
            <HStack>
              <Icon as={FaClock} color="gray.500" />
              <Text>Business Hours:: 09:00 - 18:00</Text>
            </HStack>

            <VStack align="flex-start" spacing={2} width="100%">
              <HStack width="100%">
                <Icon as={FaEnvelope} color="gray.500" />
                <VStack align="flex-start" spacing={0}>
                  <Text fontWeight="medium">General Address:</Text>
                  <Link href="mailto:office@travel-time.ro" color={linkColor}>
                    office@travel-time.ro
                  </Link>
                </VStack>
              </HStack>

              <HStack width="100%">
                <Icon as={FaEnvelope} color="gray.500" />
                <VStack align="flex-start" spacing={0}>
                  <Text fontWeight="medium">Tourism Department:</Text>
                  <Link href="mailto:turism@travel-time.ro" color={linkColor}>
                    turism@travel-time.ro
                  </Link>
                </VStack>
              </HStack>

              <HStack width="100%">
                <Icon as={FaEnvelope} color="gray.500" />
                <VStack align="flex-start" spacing={0}>
                  <Text fontWeight="medium">Ticketing Department:</Text>
                  <Link
                    href="mailto:ticketing@travel-time.ro"
                    color={linkColor}
                  >
                    ticketing@travel-time.ro
                  </Link>
                </VStack>
              </HStack>

              <HStack width="100%">
                <Icon as={FaEnvelope} color="gray.500" />
                <VStack align="flex-start" spacing={0}>
                  <Text fontWeight="medium">Sales Department:</Text>
                  <Link
                    href="mailto:ticketing@travel-time.ro"
                    color={linkColor}
                  >
                    parteneri@travel-time.ro
                  </Link>
                </VStack>
              </HStack>

              <HStack width="100%">
                <Icon as={FaPhone} color="gray.500" />
                <VStack align="flex-start" spacing={0}>
                  <Text fontWeight="medium">Phone Number:</Text>
                  <Link href="tel:+40 371 12 12 72" color={linkColor}>
                    +40 371 12 12 72
                  </Link>
                </VStack>
              </HStack>
            </VStack>
          </VStack>
        </ContactCard>
      </SimpleGrid>
    </Container>
  );
};

export default ContactInfo;
