import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Footer from "./components/footer/footer";
import Header from "./components/header/headerNavBarNew";
import Home from "./pages/home/home";
import Contact from "./pages/contact/contact";
import InformatiiUtile from "./pages/informatiiutile/informatiiutile";
import FourZeroFour from "./pages/404/404";
import B2B from "./pages/b2b/b2b";
import IstorieSiArta from "./pages/istorie-arta/istorie-arta";
import Plimbare from "./pages/plimbare/plimbare";
import Distractie from "./pages/distractie/distractie";
import ConditiiDeCalatorie from "./pages/conditii-de-calatorie/conditiidecalatorie";
import ComunicatDePresa1 from "./pages/comunicatdepresa/comunicatdepresa";
import ComunicatDePresa2 from "./pages/comunicatdepresa/comunicatdepresa2";
import ComunicatDePresa3 from "./pages/comunicatdepresa/comunicatdepresa3";
import Corporate from "./pages/corporate/corporate2";
import Leisure from "./pages/leisure/leisure2";
import TermenisiConditii from "./pages/termenisiconditii/termenisiconditii";
import Services from "./pages/servicii/servicii";
import Capabilities from "./pages/consultanta/capabilitati";
// import { LanguageProvider } from "./context/languageContext";
import PDFConverter from "./pages/pdfConverter/pdfConvert";

// import pagini continente
import Blog from "./pages/blog/blog";
import Romania from "./pages/paginicontinente/romania";
import Asia from "./pages/paginicontinente/asia";
import Africa from "./pages/paginicontinente/africa";
import AmericaDeSud from "./pages/paginicontinente/americadesud";
import Europa from "./pages/paginicontinente/europa";
import AmericaDeNord from "./pages/paginicontinente/americadenord";
import AustraliaSiOceania from "./pages/paginicontinente/australiasioceania";

// import pagini vacante
import Revelion from "./pages/paginiVacante/revelion";

// Analytisc Google
import ReactGA from "react-ga4";

//Reusable component

import ReusableComponentDinamicPages from "./components/reusable-component-grid/reusable-component-dinamic-pages";
import ReusableComponentDinamicPagesVacanteRevelion from "./components/reusable-component-oferte/reusable-component-dinamic-pages-vacante";

ReactGA.initialize(process.env.React_App_Tracking_ID);

function App() {
  ReactGA.send(window.location.pathname);

  return (
    <div className="app">
      <div className="container-app">
        <Router>
          <Header />
          <Routes>
            {/* Romanian Routes */}
            <Route path="/ro" element={<Home />} />

            <Route path="/" element={<Home />}></Route>
            <Route path="/corporate" element={<Corporate />}></Route>
            <Route path="/pdfconverter" element={<PDFConverter />}></Route>
            <Route path="/leisure" element={<Leisure />}></Route>
            <Route path="/servicii" element={<Services />}></Route>
            <Route path="/capabilitati" element={<Capabilities />}></Route>
            <Route path="/blog" element={<Blog />}></Route>
            <Route
              path="/informatii-utile/"
              element={<InformatiiUtile />}
            ></Route>
            <Route path="/istorie-si-arta/" element={<IstorieSiArta />}></Route>
            <Route path="/distractie/" element={<Distractie />}></Route>
            <Route path="/plimbare/" element={<Plimbare />}></Route>
            <Route path="/agentii-partenere" element={<B2B />}></Route>
            <Route
              path="/conditiidecalatorie"
              element={<ConditiiDeCalatorie />}
            ></Route>
            <Route
              path="/comunicat-de-presa-proiect-lansare"
              element={<ComunicatDePresa1 />}
            ></Route>
            <Route
              path="/comunicat-de-presa-proiect-finalizare"
              element={<ComunicatDePresa2 />}
            ></Route>
            <Route
              path="/comunicat-finalizare-proiect-ID-131118"
              element={<ComunicatDePresa3 />}
            ></Route>
            <Route path="/contact" element={<Contact />}></Route>
            <Route
              path="/termenisiconditii"
              element={<TermenisiConditii />}
            ></Route>

            {/*Rute pagini continente */}
            <Route path="/romania/" element={<Romania />}></Route>
            <Route path="/europa/" element={<Europa />}></Route>
            <Route path="/asia/" element={<Asia />}></Route>
            <Route path="/africa/" element={<Africa />}></Route>
            <Route path="/americadenord/" element={<AmericaDeNord />}></Route>
            <Route path="/americadesud/" element={<AmericaDeSud />}></Route>
            <Route
              path="/australiasioceania/"
              element={<AustraliaSiOceania />}
            ></Route>

            {/* Rute Vacante */}
            <Route path="/revelion/" element={<Revelion />}></Route>
            <Route path="/sejururi/" element={<Revelion />}></Route>
            <Route path="/circuite /" element={<Revelion />}></Route>
            <Route path="/revelion/" element={<Revelion />}></Route>

            {/* Rute Albume */}
            <Route
              exact
              path="/:albumId"
              element={<ReusableComponentDinamicPages />}
            ></Route>
            <Route
              exact
              path="/revelion/:albumId"
              element={<ReusableComponentDinamicPagesVacanteRevelion />}
            ></Route>
            {/* 404 error page routes */}
            <Route path="*" element={<FourZeroFour />}></Route>
          </Routes>
          <Footer />
        </Router>
      </div>
    </div>
  );
}

export default App;
