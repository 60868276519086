import React, { useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Grid,
  Heading,
  Input,
  Text,
  VStack,
  Alert,
  AlertIcon,
  useToast,
} from "@chakra-ui/react";

const PDFConverter = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [processedData, setProcessedData] = useState(null);
  const toast = useToast();

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === "application/pdf") {
      setFile(selectedFile);
      setError(null);
    } else {
      setError("Please select a valid PDF file");
    }
  };

  const handleUpload = async () => {
    if (!file) {
      setError("Please select a file first");
      return;
    }

    setLoading(true);
    setError(null);

    const formData = new FormData();
    formData.append("pdf", file);

    try {
      const response = await axios.post(
        "http://localhost:3001/api/process-pdf",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setProcessedData(response.data);
      toast({
        title: "PDF processed successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      setError(err.response?.data?.error || "Error processing PDF");
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = async () => {
    try {
      const response = await axios.post(
        "http://localhost:3001/api/generate-pdf",
        processedData,
        {
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "beautified-itinerary.pdf");
      document.body.appendChild(link);
      link.click();
      link.remove();

      toast({
        title: "PDF downloaded successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        title: "Error downloading PDF",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Container maxW="container.lg" py={8}>
      <VStack spacing={8}>
        <Heading textAlign="center" color="blue.700">
          Travel Itinerary Beautifier
        </Heading>

        {/* Upload Section */}
        <Box
          w="full"
          borderWidth={2}
          borderStyle="dashed"
          borderColor="blue.400"
          rounded="lg"
          p={8}
          bg="blue.50"
          textAlign="center"
        >
          <Input
            type="file"
            accept=".pdf"
            onChange={handleFileChange}
            mb={4}
            p={1}
          />
          <Button
            colorScheme="blue"
            isLoading={loading}
            loadingText="Processing..."
            isDisabled={!file}
            onClick={handleUpload}
            size="lg"
          >
            Convert PDF
          </Button>
        </Box>

        {/* Error Message */}
        {error && (
          <Alert status="error" rounded="md">
            <AlertIcon />
            {error}
          </Alert>
        )}

        {/* Results Display */}
        {processedData && (
          <Box w="full" bg="white" rounded="lg" shadow="md" p={6}>
            <Flex
              justify="space-between"
              align="center"
              mb={6}
              pb={4}
              borderBottomWidth={1}
            >
              <Heading size="lg">Travel Itinerary</Heading>
              <Button colorScheme="green" onClick={handleDownload}>
                Download PDF
              </Button>
            </Flex>

            {/* Header Information */}
            <Box bg="gray.50" p={4} rounded="md" mb={6}>
              <Heading size="md" color="gray.700" mb={4}>
                Booking Details
              </Heading>
              <VStack align="stretch" spacing={2}>
                <Text>
                  <strong>Booking Reference:</strong> {processedData.bookingRef}
                </Text>
                <Text>
                  <strong>Issue Date:</strong> {processedData.issueDate}
                </Text>
                <Text>
                  <strong>Passenger:</strong> {processedData.passengerName}
                </Text>
              </VStack>
            </Box>

            {/* Flights Information */}
            <Box mb={6}>
              <Heading size="md" color="gray.700" mb={4}>
                Flight Details
              </Heading>

              {/* Outbound Flight */}
              <Box bg="gray.50" p={4} rounded="md" mb={4}>
                <Heading size="sm" color="gray.700" mb={4}>
                  Outbound Flight
                </Heading>
                <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                  <VStack align="stretch" spacing={2}>
                    <Text>
                      <strong>Flight:</strong>{" "}
                      {processedData.outbound?.flightNumber}
                    </Text>
                    <Text>
                      <strong>Date:</strong> {processedData.outbound?.date}
                    </Text>
                    <Text>
                      <strong>Departure:</strong>{" "}
                      {processedData.outbound?.departure}
                    </Text>
                    <Text>
                      <strong>Arrival:</strong>{" "}
                      {processedData.outbound?.arrival}
                    </Text>
                  </VStack>
                  <VStack align="stretch" spacing={2}>
                    <Text>
                      <strong>Duration:</strong>{" "}
                      {processedData.outbound?.duration}
                    </Text>
                    <Text>
                      <strong>Class:</strong> {processedData.outbound?.class}
                    </Text>
                    <Text>
                      <strong>Equipment:</strong>{" "}
                      {processedData.outbound?.equipment}
                    </Text>
                    <Text>
                      <strong>Baggage:</strong>{" "}
                      {processedData.outbound?.baggage}
                    </Text>
                  </VStack>
                </Grid>
              </Box>

              {/* Return Flight */}
              <Box bg="gray.50" p={4} rounded="md">
                <Heading size="sm" color="gray.700" mb={4}>
                  Return Flight
                </Heading>
                <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                  <VStack align="stretch" spacing={2}>
                    <Text>
                      <strong>Flight:</strong>{" "}
                      {processedData.return?.flightNumber}
                    </Text>
                    <Text>
                      <strong>Date:</strong> {processedData.return?.date}
                    </Text>
                    <Text>
                      <strong>Departure:</strong>{" "}
                      {processedData.return?.departure}
                    </Text>
                    <Text>
                      <strong>Arrival:</strong> {processedData.return?.arrival}
                    </Text>
                  </VStack>
                  <VStack align="stretch" spacing={2}>
                    <Text>
                      <strong>Duration:</strong>{" "}
                      {processedData.return?.duration}
                    </Text>
                    <Text>
                      <strong>Class:</strong> {processedData.return?.class}
                    </Text>
                    <Text>
                      <strong>Equipment:</strong>{" "}
                      {processedData.return?.equipment}
                    </Text>
                    <Text>
                      <strong>Baggage:</strong> {processedData.return?.baggage}
                    </Text>
                  </VStack>
                </Grid>
              </Box>
            </Box>

            {/* Additional Information */}
            <Box bg="gray.50" p={4} rounded="md">
              <Heading size="md" color="gray.700" mb={4}>
                Additional Information
              </Heading>
              <VStack align="stretch" spacing={2}>
                <Text>
                  <strong>E-ticket:</strong> {processedData.eticket}
                </Text>
                <Text>
                  <strong>CO2 Emission:</strong> {processedData.co2Emission}
                </Text>
              </VStack>
            </Box>
          </Box>
        )}
      </VStack>
    </Container>
  );
};

export default PDFConverter;
