import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  IconButton,
  Button,
  useBreakpointValue,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  useColorModeValue,
  Container,
  VStack,
  HStack,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaInstagram,
  FaWhatsapp,
  FaBars,
  FaArrowUp,
} from "react-icons/fa";

const NavbarHeader = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [scrolled, setScrolled] = useState(false);
  const [showScrollTop, setShowScrollTop] = useState(false);

  const isMobile = useBreakpointValue({ base: true, md: false });
  const showSocialIcons = useBreakpointValue({ base: false, lg: true });

  // Background color transition based on scroll
  const bgColor = useColorModeValue(
    scrolled ? "rgba(255, 255, 255, 0.9)" : "white",
    scrolled ? "rgba(26, 32, 44, 0.9)" : "gray.800"
  );

  // Montserrat font styling
  const navStyles = {
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "500",
    letterSpacing: "0.5px",
  };

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setScrolled(offset > 50);
      setShowScrollTop(offset > 400);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

const NavButton = ({ children, to }) => (
  <Button
    as={Link}
    to={to}
    variant="ghost"
    p="4"
    fontSize="md"
    fontWeight="500"
    position="relative"
    {...navStyles}
    _hover={{
      textDecoration: "none",
      bg: "transparent",
      _after: {
        width: "100%",
      },
    }}
    _after={{
      content: '""',
      position: "absolute",
      width: "0%",
      height: "2px",
      bottom: "0",
      left: "0",
      bg: "teal.400",
      transition: "width 0.3s ease",
    }}
    onClick={scrollToTop}
  >
    {children}
  </Button>
);

const SocialIcon = ({ icon: Icon, href, label, color }) => (
  <IconButton
    as="a"
    href={href}
    target="_blank"
    aria-label={label}
    icon={<Icon />}
    size="md"
    colorScheme={color}
    variant="ghost"
    borderRadius="full"
    transition="transform 0.2s"
    _hover={{ transform: "scale(1.1)" }}
  />
);

  return (
    <Box position="sticky" top="0" zIndex="1000" {...navStyles}>
      <Container maxW="container.xl" px={4}>
        <Flex
          align="center"
          justify="space-between"
          h="70px"
          transition="all 0.3s ease"
          bg={bgColor}
          backdropFilter={scrolled ? "blur(10px)" : "none"}
          boxShadow={scrolled ? "sm" : "none"}
        >
          {/* Logo */}
          <Link to="/">
            <Box>
              <img
                src="/traveltime-logo.svg"
                alt="Travel Time Logo"
                style={{ height: "70px", width: "auto" }}
              />
            </Box>
          </Link>

          {/* Desktop Navigation */}
          {!isMobile && (
            <HStack spacing={4} display={{ base: "none", md: "flex" }}>
              <NavButton to="/">About</NavButton>
              <NavButton to="/servicii">Services</NavButton>
              <NavButton to="/capabilitati">Capabilities</NavButton>
              <NavButton to="/agentii-partenere">Booking Time</NavButton>
              <NavButton to="/contact">Contact</NavButton>
            </HStack>
          )}

          {/* Social Icons - Desktop */}
          {showSocialIcons && (
            <HStack spacing={2}>
              <SocialIcon
                icon={FaFacebookF}
                href="https://www.facebook.com/traveltimeromania"
                label="Facebook"
                color="facebook"
              />
              <SocialIcon
                icon={FaInstagram}
                href="https://www.instagram.com/traveltime.ro/"
                label="Instagram"
                color="pink"
              />
              <SocialIcon
                icon={FaWhatsapp}
                href="https://wa.me/40721242233"
                label="WhatsApp"
                color="green"
              />
            </HStack>
          )}

          {/* Mobile Menu Button */}
          {isMobile && (
            <IconButton
              aria-label="Open menu"
              icon={<FaBars size={20} />}
              variant="ghost"
              size="lg"
              onClick={onOpen}
            />
          )}
        </Flex>
      </Container>

      {/* Mobile Drawer */}
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        motionPreset="slideInRight"
      >
        <DrawerOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(5px)"
          transition="all 0.4s ease-in-out"
        />
        <DrawerContent transition="transform 0.4s ease-in-out">
          <DrawerCloseButton />
          <DrawerBody pt={12}>
            <VStack spacing={4}>
              <Link
                to="/"
                style={{ width: "100%" }}
                onClick={() => {
                  onClose();
                  scrollToTop();
                }}
              >
                <Button
                  variant="ghost"
                  fontSize="lg"
                  width="full"
                  py={6}
                  {...navStyles}
                >
                  About
                </Button>
              </Link>
              <Link
                to="/servicii"
                style={{ width: "100%" }}
                onClick={() => {
                  onClose();
                  scrollToTop();
                }}
              >
                <Button
                  variant="ghost"
                  fontSize="lg"
                  width="full"
                  py={6}
                  {...navStyles}
                >
                  Services
                </Button>
              </Link>
              <Link
                to="/capabilitati"
                style={{ width: "100%" }}
                onClick={() => {
                  onClose();
                  scrollToTop();
                }}
              >
                <Button
                  variant="ghost"
                  fontSize="lg"
                  width="full"
                  py={6}
                  {...navStyles}
                >
                  Capabilities
                </Button>
              </Link>
              <Link
                to="/agentii-partenere"
                style={{ width: "100%" }}
                onClick={() => {
                  onClose();
                  scrollToTop();
                }}
              >
                <Button
                  variant="ghost"
                  fontSize="lg"
                  width="full"
                  py={6}
                  {...navStyles}
                >
                  Booking Time
                </Button>
              </Link>
              <Link
                to="/contact"
                style={{ width: "100%" }}
                onClick={() => {
                  onClose();
                  scrollToTop();
                }}
              >
                <Button
                  variant="ghost"
                  fontSize="lg"
                  width="full"
                  py={6}
                  {...navStyles}
                >
                  Contact
                </Button>
              </Link>

              <Box pt={8}>
                <HStack spacing={4} justify="center">
                  <SocialIcon
                    icon={FaFacebookF}
                    href="https://www.facebook.com/traveltimeromania"
                    label="Facebook"
                    color="facebook"
                  />
                  <SocialIcon
                    icon={FaInstagram}
                    href="https://www.instagram.com/traveltime.ro/"
                    label="Instagram"
                    color="pink"
                  />
                  <SocialIcon
                    icon={FaWhatsapp}
                    href="https://wa.me/40721242233"
                    label="WhatsApp"
                    color="green"
                  />
                </HStack>
              </Box>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {/* Scroll to Top Button */}
      <IconButton
        aria-label="Scroll to top"
        icon={<FaArrowUp />}
        size="lg"
        position="fixed"
        bottom="4"
        right="4"
        borderRadius="full"
        opacity={showScrollTop ? 1 : 0}
        visibility={showScrollTop ? "visible" : "hidden"}
        transition="all 0.3s"
        onClick={scrollToTop}
        colorScheme="teal"
        _hover={{ transform: "translateY(-2px)" }}
      />
    </Box>
  );
};

export default NavbarHeader;

// import React, { useState, useEffect } from "react";
// import {
//   Box,
//   Flex,
//   IconButton,
//   Button,
//   useBreakpointValue,
//   useDisclosure,
//   Drawer,
//   DrawerOverlay,
//   DrawerContent,
//   DrawerCloseButton,
//   DrawerBody,
//   useColorModeValue,
//   Container,
//   VStack,
//   HStack,
//   Menu,
//   MenuButton,
//   MenuList,
//   MenuItem,
// } from "@chakra-ui/react";
// import { Link } from "react-router-dom";
// import {
//   FaFacebookF,
//   FaInstagram,
//   FaWhatsapp,
//   FaBars,
//   FaArrowUp,
//   FaGlobe,
// } from "react-icons/fa";
// import { useLanguage } from "../../context/languageContext";

// const NavbarHeader = () => {
//   const { isOpen, onOpen, onClose } = useDisclosure();
//   const [scrolled, setScrolled] = useState(false);
//   const [showScrollTop, setShowScrollTop] = useState(false);
//   const { language, toggleLanguage } = useLanguage();

//   const isMobile = useBreakpointValue({ base: true, md: false });
//   const showSocialIcons = useBreakpointValue({ base: false, lg: true });

//   // Background color transition based on scroll
//   const bgColor = useColorModeValue(
//     scrolled ? "rgba(255, 255, 255, 0.9)" : "white",
//     scrolled ? "rgba(26, 32, 44, 0.9)" : "gray.800"
//   );

//   // Montserrat font styling
//   const navStyles = {
//     fontFamily: "Montserrat, sans-serif",
//     fontWeight: "500",
//     letterSpacing: "0.5px",
//   };

//   const scrollToTop = () => {
//     window.scrollTo({ top: 0, behavior: "smooth" });
//   };

//   const NavButton = ({ children, to }) => (
//     <Button
//       as={Link}
//       to={to}
//       variant="ghost"
//       p="4"
//       fontSize="md"
//       fontWeight="500"
//       position="relative"
//       {...navStyles}
//       _hover={{
//         textDecoration: "none",
//         bg: "transparent",
//         _after: {
//           width: "100%",
//         },
//       }}
//       _after={{
//         content: '""',
//         position: "absolute",
//         width: "0%",
//         height: "2px",
//         bottom: "0",
//         left: "0",
//         bg: "teal.400",
//         transition: "width 0.3s ease",
//       }}
//       onClick={scrollToTop}
//     >
//       {children}
//     </Button>
//   );

//   const SocialIcon = ({ icon: Icon, href, label, color }) => (
//     <IconButton
//       as="a"
//       href={href}
//       target="_blank"
//       aria-label={label}
//       icon={<Icon />}
//       size="md"
//       colorScheme={color}
//       variant="ghost"
//       borderRadius="full"
//       transition="transform 0.2s"
//       _hover={{ transform: "scale(1.1)" }}
//     />
//   );

//   // Your existing useEffect and scrollToTop functions...

//   const LanguageSwitcher = ({ isMobileView = false }) => {
//     if (isMobileView) {
//       return (
//         <Button
//           variant="ghost"
//           width="full"
//           py={6}
//           fontSize="lg"
//           leftIcon={<FaGlobe />}
//           onClick={() => toggleLanguage(language === "en" ? "ro" : "en")}
//           {...navStyles}
//         >
//           {language === "en" ? "Română" : "English"}
//         </Button>
//       );
//     }

//     return (
//       <Menu>
//         <MenuButton
//           as={IconButton}
//           icon={<FaGlobe />}
//           variant="ghost"
//           aria-label="Select language"
//           size="md"
//           {...navStyles}
//           transition="all 0.3s ease"
//           _hover={{
//             transform: "scale(1.1)",
//             bg: "transparent",
//           }}
//           _active={{
//             bg: "transparent",
//           }}
//         />
//         <MenuList>
//           <MenuItem
//             onClick={() => toggleLanguage("en")}
//             fontWeight={language === "en" ? "bold" : "normal"}
//             {...navStyles}
//           >
//             English
//           </MenuItem>
//           <MenuItem
//             onClick={() => toggleLanguage("ro")}
//             fontWeight={language === "ro" ? "bold" : "normal"}
//             {...navStyles}
//           >
//             Română
//           </MenuItem>
//         </MenuList>
//       </Menu>
//     );
//   };

//   // Your existing NavButton and SocialIcon components...

//   return (
//     <Box position="sticky" top="0" zIndex="1000" {...navStyles}>
//       <Container maxW="container.xl" px={4}>
//         <Flex
//           align="center"
//           justify="space-between"
//           h="70px"
//           transition="all 0.3s ease"
//           bg={bgColor}
//           backdropFilter={scrolled ? "blur(10px)" : "none"}
//           boxShadow={scrolled ? "sm" : "none"}
//         >
//           {/* Logo */}
//           <Link to="/">
//             <Box>
//               <img
//                 src="/traveltime-logo.svg"
//                 alt="Travel Time Logo"
//                 style={{ height: "70px", width: "auto" }}
//               />
//             </Box>
//           </Link>

//           {/* Desktop Navigation */}
//           {!isMobile && (
//             <HStack spacing={4} display={{ base: "none", md: "flex" }}>
//               <NavButton to={`${language === "ro" ? "/ro" : ""}/`}>
//                 {language === "en" ? "About" : "Despre"}
//               </NavButton>
//               <NavButton to={`${language === "ro" ? "/ro" : ""}/servicii`}>
//                 {language === "en" ? "Services" : "Servicii"}
//               </NavButton>
//               <NavButton to={`${language === "ro" ? "/ro" : ""}/capabilitati`}>
//                 {language === "en" ? "Capabilities" : "Capabilități"}
//               </NavButton>
//               <NavButton
//                 to={`${language === "ro" ? "/ro" : ""}/agentii-partenere`}
//               >
//                 {language === "en" ? "Booking Time" : "Rezervări"}
//               </NavButton>
//               <NavButton to={`${language === "ro" ? "/ro" : ""}/contact`}>
//                 Contact
//               </NavButton>
//             </HStack>
//           )}

//           {/* Social Icons and Language Switcher - Desktop */}
//           {showSocialIcons && (
//             <HStack spacing={2}>
//               <LanguageSwitcher />
//               <SocialIcon
//                 icon={FaFacebookF}
//                 href="https://www.facebook.com/traveltimeromania"
//                 label="Facebook"
//                 color="facebook"
//               />
//               <SocialIcon
//                 icon={FaInstagram}
//                 href="https://www.instagram.com/traveltime.ro/"
//                 label="Instagram"
//                 color="pink"
//               />
//               <SocialIcon
//                 icon={FaWhatsapp}
//                 href="https://wa.me/40721242233"
//                 label="WhatsApp"
//                 color="green"
//               />
//             </HStack>
//           )}

//           {/* Mobile Menu Button */}
//           {isMobile && (
//             <IconButton
//               aria-label="Open menu"
//               icon={<FaBars size={20} />}
//               variant="ghost"
//               size="lg"
//               onClick={onOpen}
//             />
//           )}
//         </Flex>
//       </Container>

//       {/* Mobile Drawer */}
//       <Drawer
//         isOpen={isOpen}
//         placement="right"
//         onClose={onClose}
//         motionPreset="slideInRight"
//       >
//         <DrawerOverlay
//           bg="blackAlpha.300"
//           backdropFilter="blur(5px)"
//           transition="all 0.4s ease-in-out"
//         />
//         <DrawerContent transition="transform 0.4s ease-in-out">
//           <DrawerCloseButton />
//           <DrawerBody pt={12}>
//             <VStack spacing={4}>
//               {/* Add Language Switcher at the top of mobile menu */}
//               <Box width="100%">
//                 <LanguageSwitcher isMobileView={true} />
//               </Box>

//               <Link
//                 to={`${language === "ro" ? "/ro" : ""}/`}
//                 style={{ width: "100%" }}
//                 onClick={() => {
//                   onClose();
//                   scrollToTop();
//                 }}
//               >
//                 <Button
//                   variant="ghost"
//                   fontSize="lg"
//                   width="full"
//                   py={6}
//                   {...navStyles}
//                 >
//                   {language === "en" ? "About" : "Despre"}
//                 </Button>
//               </Link>
//               {/* Repeat for other menu items with translations */}

//               <Box pt={8}>
//                 <HStack spacing={4} justify="center">
//                   <SocialIcon
//                     icon={FaFacebookF}
//                     href="https://www.facebook.com/traveltimeromania"
//                     label="Facebook"
//                     color="facebook"
//                   />
//                   <SocialIcon
//                     icon={FaInstagram}
//                     href="https://www.instagram.com/traveltime.ro/"
//                     label="Instagram"
//                     color="pink"
//                   />
//                   <SocialIcon
//                     icon={FaWhatsapp}
//                     href="https://wa.me/40721242233"
//                     label="WhatsApp"
//                     color="green"
//                   />
//                 </HStack>
//               </Box>
//             </VStack>
//           </DrawerBody>
//         </DrawerContent>
//       </Drawer>

//       {/* Scroll to Top Button */}
//       <IconButton
//         aria-label="Scroll to top"
//         icon={<FaArrowUp />}
//         size="lg"
//         position="fixed"
//         bottom="4"
//         right="4"
//         borderRadius="full"
//         opacity={showScrollTop ? 1 : 0}
//         visibility={showScrollTop ? "visible" : "hidden"}
//         transition="all 0.3s"
//         onClick={scrollToTop}
//         colorScheme="teal"
//         _hover={{ transform: "translateY(-2px)" }}
//       />
//     </Box>
//   );
// };

// export default NavbarHeader;
